import { AlbumPage } from '@api/gql/graphql'

import { AlbumPagePagination } from '../types'

export const getSpreadLabel = (
  page: AlbumPage & AlbumPagePagination,
  index: number,
  spreadPages: (AlbumPage & AlbumPagePagination)[],
) => {
  if (spreadPages.length >= 2) {
    return `Page ${page.page_number}-${spreadPages[index + 1]?.page_number}`
  }

  return `Page ${page.page_number}`
}
