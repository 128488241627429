import { FC, useContext } from 'react'
import { Album } from '@api/gql/graphql'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { WindowSizesContext } from '../context'

type Props = {
  album?: Album | null
}

export const AlbumHead: FC<Props> = (props) => {
  const windowSizes = useContext(WindowSizesContext)
  const isMobile = useIsMobile()
  const headStyle = { width: `${isMobile ? windowSizes.outer.width : windowSizes.outerHalf.width}px` }

  return (
    <div className="hidden md:flex flex-col justify-center" style={headStyle}>
      <div className="text-3xl font-medium text-center mb-1">
        {props.album?.title}
        {' '}
        {props.album?.subtitle}
      </div>
    </div>
  )
}
