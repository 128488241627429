import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode;
  isWide?: boolean
  id?: string
}

export const SpreadLayout: FC<Props> = (props) => {
  return (
    <div
      id={props.id}
      className={props.isWide
        ? 'md:flex md:justify-center'
        : 'grid grid-cols-2 md:grid-cols-2 md:gap-4'}
    >
      {props.children}
    </div>
  )
}
