import { FC } from 'react'
import { Album } from '@api/gql/graphql'

import { AlbumItem } from './album-item'

type Props = {
  albums: Album[]
  currentAlbumId: string
  onChangeCurrentAlbumId: (albumId: string) => void
}

export const AlbumsNavigation: FC<Props> = (props) => {
  return (
    <div className="flex flex-row gap-x-2 overflow-x-scroll scrollbar pb-2">
      {props.albums.map((album) => (
        <AlbumItem
          key={album.id}
          album={album}
          isActive={props.currentAlbumId === album.id}
          onClick={() => props.onChangeCurrentAlbumId(album.id)}
        />
      ))}
    </div>
  )
}
