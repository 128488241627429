import {
  faCheck,
  faClose,
  faInfo,
  faWarning,
} from '@fortawesome/free-solid-svg-icons'

export enum AlertType {
  info = 'info',
  success = 'success',
  error = 'error',
  danger = 'danger',
}

export const ICONS = {
  [AlertType.info]: faInfo,
  [AlertType.success]: faCheck,
  [AlertType.danger]: faWarning,
  [AlertType.error]: faClose,
}

export const THEME: { [key: string]: { text: string, container: string } } = {
  [AlertType.info]: {
    text: 'text-blue-700',
    container: 'bg-blue-50',
  },
  [AlertType.success]: {
    text: 'text-green-700',
    container: 'bg-green-50',
  },
  [AlertType.danger]: {
    text: 'text-yellow-700',
    container: 'bg-yellow-50',
  },
  [AlertType.error]: {
    text: 'text-red-700',
    container: 'bg-red-50',
  },
}
