import { FC } from 'react'
import { TaskStatus, TaskType } from '@api/gql/graphql'
import { dropTask, subscriptionOnTask } from '@api/schemas'
import { useMutation, useSubscription } from '@apollo/client'
import { toast } from '@components/toast'
import { toHumanReadable } from '@helpers/i18n'
import { Button } from '@nextui-org/button'

import { deleteAlbumDuplicates } from '../api'

type Props = {
  albumId?: string | null;
  onCompleted: () => void
};

export const ImageProcessorStatus: FC<Props> = (props) => {
  const [dropTaskMutation] = useMutation(dropTask, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const subscriptionOnImageProcessorState = useSubscription(subscriptionOnTask, {
    variables: { taskType: TaskType.ImageProcessor, input: { album_id: props.albumId } },
    onData: async (data) => {
      const task = data.data.data?.subscribe_on_task
      if (!task) {
        return
      }

      if (task.status === TaskStatus.Completed) {
        await dropTaskMutation({
          variables: { taskType: TaskType.CreateAlbums, input: {} },
        })
        props.onCompleted()
      }
    },
  })

  const task = subscriptionOnImageProcessorState.data?.subscribe_on_task
  const status = toHumanReadable(task?.status || '').toLowerCase()

  const [
    deleteAlbumDuplicatesMutation,
    deleteAlbumDuplicatesState,
  ] = useMutation(deleteAlbumDuplicates, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
    onCompleted: () => {
      toast.success('All duplicates have been deleted')
    },
  })

  const onDeleteDuplicates = async () => {
    if (!props.albumId) {
      return
    }

    await deleteAlbumDuplicatesMutation({
      variables: { albumId: props.albumId },
    })
  }

  if (task?.status === TaskStatus.Completed) {
    return (
      <Button
        onClick={onDeleteDuplicates}
        variant="light"
        className="underline"
        isLoading={deleteAlbumDuplicatesState.loading}
      >
        Delete duplicates
      </Button>
    )
  }

  if (task?.status && [TaskStatus.InProgress, TaskStatus.InProgress].includes(task?.status)) {
    return (
      <Button
        onClick={onDeleteDuplicates}
        variant="light"
        isDisabled
      >
        {`Search for duplicates: ${status}`}
      </Button>
    )
  }

  return <div className="h-[40px]" />
}
