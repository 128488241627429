import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const PageLabel: FC<Props> = (props) => (
  <div className="text-xl md:text-3xl font-medium mb-2 h-8 md:h-9">
    {props.children}
  </div>
)
