import {
  FC, useMemo,
} from 'react'
import { TaskStatus, TaskType } from '@api/gql/graphql'
import { dropTask, subscriptionOnTask } from '@api/schemas'
import { useMutation, useSubscription } from '@apollo/client'
import { toast } from '@components/toast'
import { faRepeat, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toHumanReadable } from '@helpers/i18n'
import { Button } from '@nextui-org/button'
import { Modal, ModalBody, ModalContent } from '@nextui-org/modal'
import { Spinner } from '@nextui-org/spinner'

type Props = {
  onCompleted: () => void
};

export const RefreshImagesStatus: FC<Props> = (props) => {
  const [dropTaskMutation, dropTaskState] = useMutation(dropTask, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const subscriptionOnRefreshImagesState = useSubscription(subscriptionOnTask, {
    variables: {
      taskType: TaskType.RefreshImages,
      input: {},
    },
    onData: async (data) => {
      const task = data.data.data?.subscribe_on_task
      if (!task) {
        return
      }

      if (task.status === TaskStatus.Completed) {
        await dropTaskMutation({
          variables: { taskType: TaskType.CreateAlbums, input: {} },
        })
        props.onCompleted()
      }
    },
  })

  const task = subscriptionOnRefreshImagesState.data?.subscribe_on_task

  const isOpen = useMemo(() => {
    if (!task) {
      return false
    }

    return [TaskStatus.InQueue, TaskStatus.InProgress].includes(task.status)
  }, [task, subscriptionOnRefreshImagesState.data])

  const lastStep = useMemo(() => {
    if (!task?.steps.length) {
      return '-'
    }

    return task.steps[task.steps.length - 1]
  }, [task?.steps])

  const isError = task?.status === TaskStatus.Error

  const onTryAgain = async () => {
    await dropTaskMutation({
      variables: { taskType: TaskType.CreateAlbums, input: {} },
    })
    window.location.reload()
  }

  return (
    <Modal
      placement="center"
      size="sm"
      backdrop="blur"
      isOpen={isOpen}
      hideCloseButton
    >
      <ModalContent className="py-10">
        {(onClose) => (
          <form onSubmit={onClose}>
            <ModalBody>
              <div className="flex flex-col items-center">
                <Spinner
                  className="mb-4"
                  size="sm"
                />

                {isError && (
                  <FontAwesomeIcon
                    className="mb-4 size-5 text-red-500"
                    icon={faWarning}
                  />
                )}

                <div className="text-lg font-bold">{toHumanReadable(task?.status || '')}</div>
                <div className="text-gray-600 text-center">{lastStep}</div>

                {isError && (
                  <Button
                    variant="flat"
                    className="w-fit mt-4"
                    size="sm"
                    startContent={<FontAwesomeIcon icon={faRepeat} />}
                    isLoading={dropTaskState.loading}
                    onClick={onTryAgain}
                  >
                    Try again
                  </Button>
                )}
              </div>
            </ModalBody>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}
