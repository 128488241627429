import { FC, useMemo } from 'react'
import { AlertType, ICONS, THEME } from '@components/alert/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clsx } from 'clsx'

type Props = {
  type: AlertType
  message?: string
  className?: string
}

export const Alert: FC<Props> = (props) => {
  const icon = useMemo(() => ICONS[props.type], [props.type])
  const theme = useMemo(() => THEME[props.type], [props.type])

  if (!props.message) {
    return <></>
  }

  return (
    <div className={clsx(
      'items-center py-2 px-4 rounded-md flex gap-2 break-all',
      theme.container,
      props.className,
    )}
    >
      <FontAwesomeIcon width={20} height={20} icon={icon} className={theme.text} />
      <div className={clsx('text-base', theme.text)}>{props.message}</div>
    </div>
  )
}
