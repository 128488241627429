import { FC } from 'react'
import { Album } from '@api/gql/graphql'
import { clsx } from 'clsx'

type Props = {
  album: Album
  isActive: boolean
  onClick: () => void
}

export const AlbumItem: FC<Props> = (props) => (
  <button
    type="button"
    key={props.album.id}
    className={clsx(
      'border rounded-md p-2 min-w-36 lg:min-w-auto transition-colors',
      props.isActive
        ? 'border-primary hover:border-primary-500'
        : 'border-gray-300 hover:border-gray-500',
    )}
    onClick={props.onClick}
  >
    <div className="text-sm lg:text-base text-left">{props.album.title}</div>
    <div className="text-xs lg:text-sm text-left">
      {props.album.subtitle}
    </div>
  </button>
)
