import {
  FC, ReactNode, useCallback,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlbumPageType } from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { toast } from '@components/toast'
import { faImage, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApolloError } from '@helpers/apollo-error'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { getAlbum, refreshImages } from './api'
import {
  AlbumHead,
  ImageProcessorStatus,
  PageItem,
  PageLabel,
  RefreshImagesStatus,
  SpreadLayout,
} from './components'
import { WindowSizesContext } from './context'
import { getSpreadLabel, usePages, useWindowSizes } from './utils'

type Props = {
  albumId: string
  editable: boolean
  children?: (count: number) => ReactNode
}

export const AlbumView: FC<Props> = (props) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const location = useLocation()
  const { openModal } = useModal()

  const [refreshImagesMutation] = useMutation(refreshImages, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-and-network',
    variables: { id: props.albumId as string },
    onCompleted: async (res) => {
      if (!res.album?.order_id) {
        return
      }

      // Переход к развороту после возвращения со страницы разворота
      if (location.hash) {
        const spreadId = location.hash.slice(1)
        document.getElementById(spreadId)?.scrollIntoView({ behavior: 'instant' })
      }

      await refreshImagesMutation()
    },
  })

  const pageError = useApolloError([
    getAlbumState.error,
  ])

  const album = getAlbumState.data?.album ?? null
  const pages = album?.pages ?? []

  const { pagesLive, spreads } = usePages(pages)
  const windowSizes = useWindowSizes()

  const onOpenSelectAlbumCover = () => {
    openModal({
      type: ModalType.SELECT_ALBUM_COVER_MODAL,
      album_id: props.albumId,
    })
  }

  const onOpenEditAlbumMetadata = () => {
    openModal({
      type: ModalType.EDIT_ALBUM_METADATA_MODAL,
      album_id: props.albumId,
    })
  }

  const headStyle = { width: `${isMobile ? windowSizes.outer.width : windowSizes.outerHalf.width}px` }

  // Переход к развороту
  const onNavigateSpreadView = useCallback((spreadIndex: number) => {
    return () => {
      if (!album || !isMobile || spreadIndex === 0) {
        return
      }

      navigate(`/orders/${album.order_id}/${album.id}/${spreadIndex}`)
    }
  }, [spreads, album])

  return (
    <WindowSizesContext.Provider value={windowSizes}>
      <div className="flex flex-col gap-y-2 md:gap-y-5 relative" ref={windowSizes.ref}>
        <Alert type={AlertType.error} message={pageError} />

        <div className="flex flex-col items-center w-full">
          <AlbumHead album={getAlbumState.data?.album} />

          <div className="md:mb-10">
            <ImageProcessorStatus albumId={props.albumId} onCompleted={getAlbumState.refetch} />
          </div>

          <div style={headStyle}>
            <div className="text-2xl md:text-3xl font-medium text-left mb-2 md:mb-6">
              Cover
            </div>

            <div className="grid grid-cols-2 gap-2">
              <Button
                variant="flat"
                isDisabled={!props.editable}
                className="text-black bg-gray-very-light"
                startContent={<FontAwesomeIcon icon={faImage} className="text-gray-400" />}
                size={isMobile ? 'sm' : 'md'}
                onClick={onOpenSelectAlbumCover}
              >
                Choose photos
              </Button>

              <Button
                variant="flat"
                isDisabled={!props.editable}
                className="text-black bg-gray-very-light"
                startContent={<FontAwesomeIcon icon={faPencil} className="text-gray-400" />}
                size={isMobile ? 'sm' : 'md'}
                onClick={onOpenEditAlbumMetadata}
              >
                Edit name
              </Button>
            </div>
          </div>
        </div>

        {album && spreads.map((spreadPages, spreadIndex) => (
          <SpreadLayout
            id={`spread-${spreadIndex}`} // Для перехода к развороту по url
            key={spreadIndex}
            isWide={spreadIndex === 0}
          >
            {spreadPages.map((page, index) => (
              <PageItem
                key={page.id}
                album={album}
                page={page}
                spreadIndex={spreadIndex}
                isLeft={index === 0}
                isRight={index === 1}
                isControl={!isMobile}
                isSortable={spreads.length - 1 !== spreadIndex}
                pageLabel={(
                  <PageLabel>
                    {index === 0 ? getSpreadLabel(page, index, spreadPages) : ''}
                  </PageLabel>
                )}
                isWide={spreadIndex === 0 && isMobile} // В mobile страница на всю ширину
                isDivider={spreadPages.length > 1}
                onClick={onNavigateSpreadView(spreadIndex)}
                spreadPageTypes={spreadPages.map((p) => p.type as AlbumPageType)}
              />
            ))}
          </SpreadLayout>
        ))}

        {props.children ? props.children(pagesLive.length) : <></>}

        {album?.order_id && (
          <RefreshImagesStatus
            onCompleted={getAlbumState.refetch}
          />
        )}
      </div>
    </WindowSizesContext.Provider>
  )
}
