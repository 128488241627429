import { useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import { ERROR } from '@constants/error'

export const useApolloError = (args: (ApolloError | undefined)[]) => {
  return useMemo(() => {
    const arg = args.find((value) => !!value)

    if (arg?.graphQLErrors.length) {
      return arg?.graphQLErrors.map((err) => err.message).join('\n')
    }

    const networkError = arg?.networkError as any
    if (networkError?.result?.errors.length) {
      return networkError?.result?.errors.map((e: any) => e.message).join('\n')
    }
    return networkError?.message ?? undefined
  }, [args])
}

export const useApolloErrorCode = (args: (ApolloError | undefined)[]): ERROR | null => {
  return useMemo(() => {
    const arg = args.find((value) => !!value)

    if (arg?.graphQLErrors.length) {
      return (arg?.graphQLErrors[0].extensions.code as ERROR) ?? null
    }

    return null
  }, [args])
}
